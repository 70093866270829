<template>
  <section id="section-feature">
    <div class="section-feature__wrap">
      <div class="section__head">
        <h3>FEATURE</h3>
      </div>
      <div class="section-feature__items">
        <a class="section-feature__item" href="#link__authentric">
          <div class="section-feature__item-img">
            <img src="../../assets/lp_new/feature_btn_1.png" alt="feature1" />
          </div>
          <p class="section-feature__item-text">
            AUTHENTIC<br /><span>
              本格的な成分配合
            </span>
          </p>
        </a>
        <a class="section-feature__item" href="#link__functional">
          <div class="section-feature__item-img">
            <img src="../../assets/lp_new/feature_btn_2.png" alt="feature1" />
          </div>
          <p class="section-feature__item-text">
            FUNCTIONAL<br /><span>
              機能的で使いやすい
            </span>
          </p>
        </a>
        <a class="section-feature__item" href="#link__trust">
          <div class="section-feature__item-img">
            <img src="../../assets/lp_new/feature_btn_3.png" alt="feature1" />
          </div>
          <p class="section-feature__item-text">
            TRUST<br /><span>
              最新の科学に基づく安心の品質
            </span>
          </p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* section-feature */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */

#section-feature {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #272727;
  height: 450px;
}

.section-feature__wrap {
  width: 100%;
  max-width: 1020px;
}

.section-feature__subhead {
  margin-top: 15px;
}

.section-feature__subhead p {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 70px;
}

.section-feature__items {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  flex-wrap: wrap;
  margin: 50px auto;
}

.section-feature__items-head {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.section-feature__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #a69c7d;
  width: calc(33% - 28px);
  border-radius: 8px;
  margin: 0 14px;
  text-align: center;
}

.section-feature__item:link {
  color: white;
  text-decoration: none;
}

.section-feature__item:hover {
  color: white;
  text-decoration: none;
}

.section-feature__item-img {
  width: 100px;
}

.section-feature__item-img > img {
  width: 100%;
  vertical-align: bottom;
}

.section-feature__item p {
  font-size: 28px;
  font-weight: bold;
  margin: 0 auto;
  padding: 18px 0;
}

.section-feature__item p > span {
  font-size: 13px;
  display: block;
  font-weight: 400;
}

@media screen and (max-width: 1020px) {
  #section-feature {
    height: auto;
    padding: 30px 0;
  }

  .section-feature__items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 15px auto;
  }

  .section-feature__item {
    width: 100%;
    max-width: 420px;
    margin: 15px auto;
  }
}

@media screen and (max-width: 768px) {
  .section-feature__item p {
    font-size: 16px;
    padding: 9px 0;
  }

  .section-feature__item p > span {
    font-size: 12px;
  }

  .section-feature__item {
    max-width: 280px;
  }

  .section-feature__item-img {
    width: 60px;
  }
}
</style>
